<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Edit Transport System</h4>
                        <form @submit.prevent="updateBiker">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Name</label>
                                        <input
                                                type="text"
                                                v-model="bikerData.name"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label >Surname</label>
                                        <input
                                                type="text"
                                                v-model="bikerData.surname"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input
                                                type="email"
                                                v-model="bikerData.email"
                                                class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label> Number</label>
                                    <input
                                            type="number"
                                            v-model="bikerData.number"
                                            class="form-control"
                                    />
                                </div>

                            </div>
                            <div style="display: flex; justify-content: flex-end">
                                <button class="btn btn-primary" type="submit">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="lodader__content" v-if="loader">
            <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
        </div>  
    </layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import {axios_get, axios_put} from "../../../helpers/helper";


    export default {
        page: {
            title: "Edit Transport System",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
        data() {
            return {
                title: "Edit Transport System",
                bikerData: null,
                id: null,
                loader: false,
                items: [
                    {
                        text: "View Transports Systems",
                        href: "/biker/bikers"
                    },
                    {
                        text: "Edit Transport System",
                        active: true
                    }
                ],
            };

        },
        validations: {

        },
        methods: {
            updateBiker(){
                this.loader=true
                console.log(this.bikerData)
                axios_put("/bikers/"+this.id, this.bikerData, ()=>{
                    window.location.href='/biker/bikers'
                }, (e)=>{
                    console.log('error', e)
                }, ()=>{
                    console.log('final')
                    this.loader=false
                })
            }
        },
        mounted() {
            console.log(this.$route.params.id)
            this.id=this.$route.params.id
            axios_get("/bikers/"+this.id,{},(d)=>{
                    console.log(d)
                    this.bikerData=d
                    console.log("success", d);
                },(e)=>{
                    console.log("error", e);
                },()=>{
                    console.log("finishing");
                }
            );
        }
    };
</script>

<style scoped>
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>